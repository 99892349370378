import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

import Link from './link';
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';



const Header = () => {
	const { originalPath, t } = useI18next();

	return (
		<>
			<div id="header">
				<Link to="/">
					{originalPath === '/' && <span className="text-center"></span>}
					{originalPath !== '/' && (
						<span className="text-center">
							<ArrowLeftCircleFill size="20%" />
							
						</span>
					)}
				</Link>
			</div>
		</>
	);
};

export default Header;
