import PropTypes from 'prop-types';
import React from 'react';

import Footer from './footer';
import Header from './header';
import Language from './language';

const Layout = ({ children, header, location }) => {
	return (
		<>
			<Header header={header} location={location} />
			{children}
			<Footer />
			<Language />
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.array,
	header: PropTypes.object,
	location: PropTypes.object.isRequired,
};

export default Layout;
