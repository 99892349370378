import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

import Link from './link';

import flagEN from '../../images/flags/en.svg';
import flagSV from '../../images/flags/sv.svg';

const Language = () => {
	const { language, languages, originalPath } = useI18next();
	const flags = { en: flagEN, sv: flagSV };

	return (
		<div className="p-1" id="language">
			{languages.map((key) => (
				<div
					className={`mb-4 ${language === key ? 'active' : 'inactive'}`}
					key={key}
				>
					<Link language={key} to={originalPath}>
						<img alt={`Flag ${key}`} src={flags[key]} />
					</Link>
				</div>
			))}
		</div>
	);
};

export default Language;
