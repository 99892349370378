import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from '@reach/router';
import { Helmet } from 'gatsby-plugin-react-i18next';

function Seo({ description, tags, title }) {
	const { pathname } = useLocation();
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						description
						siteUrl
						title
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;
	const pageUrl = `${site.siteMetadata.siteUrl}${pathname}`;

	return (
		<Helmet titleTemplate={`%s | ${site.siteMetadata.title}`}>
			<title>{title}</title>
			<link href={pageUrl} rel="canonical" />
			<meta content={metaDescription} name="description" />
			<meta content={tags} name="keywords" />
		</Helmet>
	);
}

Seo.defaultProps = {
	description: '',
};

Seo.propTypes = {
	description: PropTypes.string,
	tags: PropTypes.string,
	title: PropTypes.string.isRequired,
};

export default Seo;
