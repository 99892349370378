import React from 'react';

const Footer = () => {
	return (
		<>
		</>
	);
};

export default Footer;
