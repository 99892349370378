import {
	Link as I18nextLink,
	I18nextContext,
} from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

const Link = ({ children, ...options }) => {
	if (options.to.indexOf('/book') !== -1) {
		const context = useContext(I18nextContext);
		const getLanguagePath = (language) => {
			return context.generateDefaultLanguagePage ||
				language !== context.defaultLanguage
				? `/${language}`
				: '';
		};
		options.to = `${getLanguagePath(
			options.language ? options.language : context.language
		)}${options.to}`;
	}

	if (
		options.to.indexOf('/book') !== -1 ||
		options.to.substring(0, 4) === 'http' ||
		options.to.indexOf('.pdf') !== -1
	) {
		options.href = options.to;
		options.to = undefined;
		return <a {...options}>{children}</a>;
	}
	return <I18nextLink {...options}>{children}</I18nextLink>;
};

Link.propTypes = {
	children: PropTypes.any,
};

export default Link;
